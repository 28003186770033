import { createContext, useContext } from 'react';

import { Service } from '../machines/app/types';

const AppContext = createContext<Service | undefined>(undefined);

export const AppContextProvider = AppContext.Provider;

/**
 * Exposes the XState service of the appMachine.
 * This allows other machines to subscribe to it and retrieve the user session.
 */
export const useAppContext = () => useContext(AppContext);
