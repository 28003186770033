import { emit } from '@zapier/events-toolkit-browser';
import { ActionType } from '@zapier/transfer-client';
import { trackFullStoryEvent } from '../fullStory';

export const TransferUserInteractionEventName =
  'zap.transfer.TransferUserInteractionEvent';

export interface Meta {
  /** Is the system emitting the event */
  system_name: 'transfer';
  /** The git SHA (aka release) associated with the code that emitted this event */
  release_id: string;
  /** The unique identifier tracking the user's behavior from creating a Transfer Zap through creation of a batches. */
  transfer_interaction_id: string;
}

export interface Context {
  /** The Zapier Account ID. This is the account that the user joined. Most users will have a fresh account created for them when they sign up but sometimes they'll join an existing account  */
  account_id: number | null;
  /** The identifier of the app used in the first step of the Zap (the trigger). */
  read_app_selected_api: string | null;
  /** Allows us to segment by read apps with or without auth. */
  read_app_has_auth: boolean | null;
  /** The key of the app's (read) action (trigger) used in the first step of the Zap. */
  read_action_key: string | null;
  read_action_type: ActionType | null;
  /** Allows us to segment by the number of needs read actions have. */
  read_action_needs_count: number | null;
  /** The identifier of the app used in the second step of the Zap (the action). */
  write_app_selected_api: string | null;
  /** Allows us to segment by write apps with or without auth. */
  write_app_has_auth: boolean | null;
  /** The key of the app's (write) action used in the second step of the Zap. */
  write_action_key: string | null;
  write_action_type: ActionType | null;
  /** Allows us to segment by the number of pre-mapping needs write actions have. */
  write_action_pre_needs_count: number | null;
  /** For *Needs screens and actions that relate to a specific field, this holds the key of that field. */
  need_key: string | null;
  /** This refers to the error details tracked using `zap.transfer.ClientErrorEvent`, via its `event_reference_id` field. */
  client_error_event_reference_id: string | null;
  /** Once the Transfer Zap has been created, events will set this field to the ID of the created Transfer Zap. */
  zap_id: number | null;
}

export interface Event {
  /** The name of the button or interaction in the code */
  interaction_name: string | null;
  /** The name of the interaction object in the screen */
  interaction_raw_text: string | null;
  /** The final goal of the interaction */
  interaction_goal: string | null;
  /** Optional string that identifies the option selected. */
  option_selected: string | null;
  /** Is this a scheduled transfer? Value is `true` if yes, `false` if one-time transfer, `null` if user hasn't picked yet. */
  scheduled_transfer_flag: boolean | null;
  /** The action that originated this event. */
  event_action:
    | 'click'
    | 'open'
    | 'close'
    | 'connect'
    | 'edit'
    | 'empty'
    | 'enter'
    | 'error'
    | 'map'
    | 'run_test'
    | 'select'
    | 'deselect'
    | 'skip_test'
    | 'learn_more';
}

type ManagedContext = Omit<Meta, 'system_name'> & Partial<Context>;

type EventData = Partial<Event> &
  Pick<
    Event,
    | 'event_action'
    | 'interaction_goal'
    | 'interaction_name'
    | 'interaction_raw_text'
    | 'option_selected'
  >;

export type TrackingFunction = (
  event: EventData,
  context?: Partial<Context>
) => Promise<void>;

export default function createTracking(initialContext: ManagedContext) {
  let ctx = initialContext;

  async function trackEvent(
    eventData: EventData,
    eventContext?: Partial<Context>
  ) {
    // merge in any new context so it's available _with_ the event
    ctx = { ...ctx, ...eventContext };

    // build the event
    const event: Meta & Context & Event = {
      // type
      event_action: eventData.event_action,

      // data
      interaction_name: eventData.interaction_name,
      interaction_raw_text: eventData.interaction_raw_text,
      interaction_goal: eventData.interaction_goal,
      option_selected: eventData.option_selected,
      scheduled_transfer_flag: eventData.scheduled_transfer_flag ?? null,

      // meta
      system_name: 'transfer',
      release_id: ctx.release_id,
      transfer_interaction_id: ctx.transfer_interaction_id,

      // context
      account_id: ctx.account_id || null,
      read_app_selected_api: ctx.read_app_selected_api || null,
      read_app_has_auth: ctx.read_app_has_auth || null,
      read_action_key: ctx.read_action_key || null,
      read_action_type: ctx.read_action_type || null,
      read_action_needs_count: ctx.read_action_needs_count || null,
      write_app_selected_api: ctx.write_app_selected_api || null,
      write_app_has_auth: ctx.write_app_has_auth || null,
      write_action_key: ctx.write_action_key || null,
      write_action_type: ctx.write_action_type || null,
      write_action_pre_needs_count: ctx.write_action_pre_needs_count || null,
      need_key: ctx.need_key || null,
      client_error_event_reference_id:
        ctx.client_error_event_reference_id || null,
      zap_id: ctx.zap_id || null,
    };

    const fullStoryEventName = `${event.event_action} ${event.interaction_name}`;
    trackFullStoryEvent(fullStoryEventName, event);

    await emit(event, TransferUserInteractionEventName);
  }

  return trackEvent;
}
