import * as React from 'react';

import createTracking, { TrackingFunction } from './tracking';
import getReleaseId from '../../utils/getReleaseId';

const defaultTrackEvent = createTracking({
  release_id: getReleaseId(),
  transfer_interaction_id: '',
});

const TrackingContext =
  React.createContext<TrackingFunction>(defaultTrackEvent);

export const TrackingProvider: React.FC<{ interactionId: string }> = ({
  children,
  interactionId,
}) => {
  const trackEvent = React.useMemo(
    () =>
      createTracking({
        release_id: getReleaseId(),
        transfer_interaction_id: interactionId,
      }),
    [interactionId]
  );

  return (
    <TrackingContext.Provider value={trackEvent}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => React.useContext(TrackingContext);
