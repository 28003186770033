const SEPARATOR = '/';

const PLACEHOLDER_URL_BASE = 'http://x';

const urlBuilder = (baseUrl: string | undefined, ...pathSegments: string[]) =>
  [baseUrl || '', ...pathSegments].join(SEPARATOR);

/** Build a URL with `zapier` or `zapier-staging` domain. */
export const zapierUrl = urlBuilder.bind(
  this,
  process.env.NEXT_PUBLIC_ZAPIER_ORIGIN
);

/** Build a URL that points to the Transfer back-end. */
export const transferUrl = urlBuilder.bind(
  this,
  process.env.NEXT_PUBLIC_TRANSFER_ORIGIN
);

/**
 * Build a fully-qualified URL that points to the Transfer front-end.
 * WARNING: Not compatible with review labs. Should only be used for server-side requests.
 */
export const localUrl = urlBuilder.bind(
  this,
  process.env.NEXT_PUBLIC_TRANSFER_FRONTEND
);

/**
 * Build a fully-qualified URL that points to the Transfer front-end.
 * Compatible with review labs. Should be used for any URLs served by the front-end.
 */
export const relativeUrl = urlBuilder.bind(this, undefined);

/**
 * Appends query params to a URL and returns the new URL string.
 * Works with relative paths and absolute URLs that may or may not already have a query string.
 */
export const appendQueryParams = (
  url: string,
  params: Record<string, string>
) => {
  const newUrl = new URL(url, PLACEHOLDER_URL_BASE);
  for (const [key, value] of Object.entries(params)) {
    newUrl.searchParams.append(key, value);
  }
  return newUrl.toString().replace(PLACEHOLDER_URL_BASE, '');
};

/**
 * Replaces the entire query string of `newUrl` with the query string of `oldUrl`.
 * Returns the new URL string. Works with relative paths and absolute URLs.
 */
export const forwardQueryParams = (newUrl: string, oldUrl?: string): string => {
  const url = new URL(newUrl, PLACEHOLDER_URL_BASE);
  // Pass along all query strings from the original URL. (Fixes TN-1504)
  url.search = oldUrl ? new URL(oldUrl, PLACEHOLDER_URL_BASE).search : '';
  return url.toString().replace(PLACEHOLDER_URL_BASE, '');
};
