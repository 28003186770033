import {
  FullStoryAPI,
  useInstallFullStory,
  useIdentifyToFullStory,
} from '@zapier/react-fullstory';
import { useCookieConsent } from '@zapier/cookies';
import { ZapierSession } from '@zapier/universal-layout';

const getUserIdentityParams = (
  interactionId: string,
  session?: ZapierSession
) =>
  session
    ? {
        id: String(session.customUserId),
        email: session.email,
        currentAccountId: session.currentAccountId,
        interactionId,
      }
    : null;

export const useFullStory = (
  interactionId: string,
  session?: ZapierSession
) => {
  // Do not install FullStory if the end user rejects analytics cookies
  const {
    byName: { analytics: allowAnalyticsCookies },
  } = useCookieConsent();
  useInstallFullStory({ isDisabled: !allowAnalyticsCookies });

  // Note: FullStory methods will not be executed if FullStory is not initialized/installed
  useIdentifyToFullStory(getUserIdentityParams(interactionId, session));
};

export const trackFullStoryEvent = (eventName: string, eventProperties?: {}) =>
  FullStoryAPI('event', eventName, eventProperties);
